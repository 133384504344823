<template>
	<v-layout justify-center align-center>
		<v-menu v-model="showMenu" offset-y right :close-on-content-click="false">
			<template v-slot:activator="{ on: menu }">
				<v-tooltip top>
					<template v-slot:activator="{ on: tooltip }">
						<v-btn :round="!isMinimized" :icon="isMinimized" small color="primary" v-on="{ ...tooltip, ...menu }">
							<v-icon>add</v-icon>
							<span v-if="!isMinimized" v-t="'client_workspace.task.create.action'" class="mx-2" />
						</v-btn>
					</template>
					<span v-t="'client_workspace.task.add_tooltip'" />
				</v-tooltip>
			</template>
			<v-list>
				<v-list-tile @click="createNewAccountingFirmTask">
					<v-list-tile-title>{{ $t('tasks.create.type.accounting_firm') }}</v-list-tile-title>
				</v-list-tile>
				<v-list-tile @click="createNewInformationRequestTask">
					<v-list-tile-title>{{ $t('tasks.create.type.information_request') }}</v-list-tile-title>
				</v-list-tile>
			</v-list>
		</v-menu>
		<TasksManagerActionAddDialog v-if="addTaskDialog" :type="newTasktype" @close="addTaskDialog = false" />
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import TasksManagerModuleGuard from '@/mixins/ModulesGuards/TasksManager/TasksManagerModuleGuard'

export default {
	name: 'TasksManagerActionAddButton',
	components: {
		TasksManagerActionAddDialog: () => ({
			component: import('@/components/TasksManager/Actions/Add/TasksManagerActionAddDialog')
		})
	},
	mixins: [TasksManagerModuleGuard],
	props: {
		minimized: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			showMenu: false,
			addTaskDialog: false,
			newTasktype: null
		}
	},
	computed: {
		...mapState({
			tasksList: state => state.tasks.list
		}),
		isMinimized: function () {
			return !this.$vuetify.breakpoint.mdAndUp || this.minimized
		}
	},
	methods: {
		createNewAccountingFirmTask: function () {
			this.newTasktype = this.service.getTaskAccountingFirmType()
			this.showMenu = false
			this.addTaskDialog = true
		},
		createNewInformationRequestTask: function () {
			this.newTasktype = this.service.getTaskInformationRequestType()
			this.showMenu = false
			this.addTaskDialog = true
		}
	}
}
</script>
